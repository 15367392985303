'use strict';

// eslint-disable-next-line require-jsdoc
function addWishlistedClass(tile) {
    $('.product-tile', tile).find('.image-container').addClass('wishlist_checked');
    // If product tile belongs to zellers, skip adding the blue heart icon
    const isZellersTile = $('.zellersTile', tile).length > 0;
    if (!isZellersTile) {
        $('.wishlistTile', tile).find('span').addClass('svg-svg-96-heart-blue svg-svg-96-heart-blue-dims');
    }
    $('.wishlistTile', tile).removeClass('select-wishlist').addClass('deselect-wishlist');
}

// eslint-disable-next-line require-jsdoc
function removeWishlistedClass(tile) {
    $('.product-tile', tile).find('.image-container').removeClass('wishlist_checked').addClass('select-wishlist');
    const isZellersTile = $('.zellersTile', tile).length > 0;
    if (!isZellersTile) {
        $('.wishlistTile', tile).find('span').removeClass('svg-svg-96-heart-blue svg-svg-96-heart-blue-dims');
        $('.wishlistTile', tile).find('span').addClass('svg-svg-105-heart-o-22-19-dims svg-svg-105-heart-o-22-19');
    }
    $('.wishlistTile', tile).removeClass('deselect-wishlist').addClass('select-wishlist');
    $('.wishlistTile', tile).find('span').show();
    $('.customer-content', tile).addClass('d-none');
    $('.image-container', tile).removeClass('wishlist_checked');
}

// eslint-disable-next-line require-jsdoc
window.markProductArrWishListedCache = function (wishlistProducts) {
    var remainingWlItems = [];
    wishlistProducts.forEach(function (product) {
        var tile = $('div[data-tile-pid=' + product.id + ']');
        if (tile.length > 0) {
            addWishlistedClass(tile);
        } else {
            remainingWlItems.push({ id: product.id });
        }
        var productDetail = $('.product-detail');
        const zellersProductDetail = $('.zellers-product').length > 0;
        if (productDetail != null) {
            productDetail.each(function () {
                var item = $(this);
                if (item.length > 0 && item.data('pid') == product.id) { // eslint-disable-line
                    if (zellersProductDetail) {
                        $('.wishlistTile', item.find('.pdpWishListCont')).removeClass('pdpdeselect-wishlist').removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
                    } else {
                        $('.wishlistTile', item.find('.primary-images-container')).removeClass('pdpdeselect-wishlist').removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
                    }
                }
            });
        }
    });
    if (remainingWlItems.length) {
        $('body').attr('data-remaining-wl-items', JSON.stringify(remainingWlItems));
    }
}

function loadWishlist(wishlistURL) {
    $.ajax({
        url: wishlistURL,
        success: function (response) {
            if (response) {
                window.wishlistProducts = response.wishlistProducts;
                window.markProductArrWishListedCache(response.wishlistProducts);
            }
        },
        error: function (err) { // eslint-disable-line
            // do nothing
        }
    });
}

module.exports = {
    markProductArrWishlisted: function () {
        setTimeout(function(){
            var wishlistURL = $('body').data('wishlisturl');
            var isCustomerNoIcons = $('#cart-carousels').data('carousel-favorites-enabled');
            if (window.wishlistAutoLoad === true) {
                if (window.wishlistProducts === undefined || window.wishlistProducts === null) {
                    // On cart page, we wait until user has scrolled down to the Wishlist before loading it
                    if (window.location.pathname.toLowerCase().includes('/cart') && isCustomerNoIcons === false) {
                        loadWishlist(wishlistURL);
                    } else if (window.location.pathname.toLowerCase().includes('/cart') && isCustomerNoIcons === true) {
                        return;
                    } else {
                        loadWishlist(wishlistURL);
                    }
                } else {
                    window.markProductArrWishListedCache(window.wishlistProducts);
                }
            }
        }, 1000);
    },
    makrSingleProductWishlisted: function (pid, $productContainer) {
        var singlewishlisturl = $('body').data('singlewishlisturl');
        const zellersProductDetail = $('.zellers-product').length > 0;
        if (pid && singlewishlisturl && $productContainer) {
            $.ajax({
                url: singlewishlisturl + '?pid=' + pid,
                success: function (response) {
                    // if qv
                    if ($productContainer.hasClass('product-quickview') || $productContainer.hasClass('primary-images-container')) {
                        if(zellersProductDetail && $productContainer.hasClass('primary-images-container')) {
                            $('.wishlistTile', $($productContainer).closest('.product-detail')).removeClass('pdpdeselect-wishlist').addClass('pdpselect-wishlist');
                            if (response.wishlisted) {
                                $('.wishlistTile', $($productContainer).closest('.product-detail')).removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
                            }
                        } else {
                            $('.wishlistTile', $productContainer).removeClass('pdpdeselect-wishlist').addClass('pdpselect-wishlist');
                            if (response.wishlisted) {
                                $('.wishlistTile', $productContainer).removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
                            }
                        }
                    } else if ($productContainer.hasClass('product-tile') || $productContainer.hasClass('product')) {
                        removeWishlistedClass($productContainer);
                        if (response.wishlisted) {
                            addWishlistedClass($productContainer);
                        }
                    }
                },
                error: function (err) { // eslint-disable-line
                    // do nothing
                }
            });
        }
    }
};
