'use strict';

/**
 * Returns price value
 * @param {models.price.default.DefaultPrice} priceModel - The price model
 * @returns {number} The price value
 */
function getPrice(priceModel) {
    if(priceModel){
        if(priceModel.sales && priceModel.sales.value){
            return priceModel.sales.value;
        }else if(priceModel.list && priceModel.list.value){
            return priceModel.list.value
        }else{
            return '';
        }
    }else{
        return '';
    }
}

/**
 * Returns list price value
 * @param {models.price.default.DefaultPrice} priceModel - The price model
 * @returns {number} The list price value
 */
function getListPrice(priceModel) {
    if(priceModel){
        if(priceModel.list && priceModel.list.value){
            return priceModel.list.value
        }else{
            return '';
        }
    }else{
        return '';
    }
}

/**
 * Add Mirakl product to cart from search page
 */
function searchMiraklAddToCart() {
    $(document).on('click', 'button.offer-add-to-cart-plp', function () {
        var bestOfferUrl = $(this).attr('data-bestofferurl');
        var operatorProductId = $(this).attr('data-pid');
        var atcOnTile = $(this).data('view');
        var $productTile = $(this).closest('.product-tile');
        var productType = $productTile.find('.offer-add-to-cart-plp').attr('data-producttype');
        if (atcOnTile === 'tile' && productType === 'master') {
            var selectASize = $(this).data('selectsizemsg');
            var addToCarLabel = $(this).data('add-to-cart-label');
            var button = $productTile.find('button.offer-add-to-cart-plp');
            button.text(selectASize);
            button.addClass('soldout').addClass('disabled');
            setTimeout(function () {
                button.text(addToCarLabel); // eslint-disable-line
                button.removeClass('soldout').removeClass('disabled'); // eslint-disable-line
            }, 3000);
            return;
        }
        $.spinner().start();
        $.ajax({
            url: bestOfferUrl,
            method: 'GET',
            success: function (data) {
                if(data){
                    var addToCartObject = data.addToCartInfo;
                    if (addToCartObject) {
                        var addToCartUrl = addToCartObject.url;
                        var pid = addToCartObject.pid;
                        var priceInfo = addToCartObject.priceInfo;
                        var productName = addToCartObject.productName;
                        var channels = addToCartObject.channels;
                        var price = getPrice(addToCartObject.price);
                        var listPrice = getListPrice(addToCartObject.price);
                        var shopName = addToCartObject.shopName;
                        var offerState = addToCartObject.offerState;
                        var shopId = addToCartObject.shopId;
                        var quantity = 1;
                        var form = {
                            pid: pid,
                            price: price,
                            channels: channels,
                            listPrice: listPrice,
                            productName: productName,
                            quantity: quantity,
                            operatorProductId: operatorProductId,
                            shopName: shopName,
                            offerState: offerState,
                            shopId: shopId,
                            priceInfo: priceInfo,
                            addToCartInfo: JSON.stringify(addToCartObject)
                        };
            
                        $(this).trigger('updateAddToCartFormData', form);
            
                        if (addToCartUrl) {
                            $.ajax({
                                url: addToCartUrl,
                                method: 'POST',
                                data: form,
                                success: function (data) {
                                    if(data){
                                        $('body').trigger('triggerHandlePostCartAdd', data);
                                        $('body').trigger('product:afterAddToCart', data);
                                    }
                                    $.spinner().stop();
                                },
                                error: function () {
                                    $.spinner().stop();
                                }
                            });
                        }else{
                            $.spinner().stop();
                        }
                    }else{
                        $.spinner().stop();
                    }
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

module.exports = {
    searchMiraklAddToCart: searchMiraklAddToCart
};
