'use strict';
var base = window._hbcProductBase;
var formFields = window._hbcFormFields;
var persistentWishlist = require('core/product/persistentWishlist');

var addOffer = require('./addOffer').addOfferToCart;
var miraklQuantityForm = require('./miraklQuantityForm').offerAvailability;

function updateSellerData(isCartRec) {
    var useBuyBox = $('.sold-by-section');

    if (useBuyBox.length !== 0) {
        var url = $('.sold-by-section').data('url');
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                $('.sold-by-section')
                    .empty()
                    .html(data);
                if (isCartRec) {
                    $('.sold-by-section .pt-4.d-flex').removeClass('pt-4');
                }
            }
        });
    }
}
/**
 * Update Buy Box with the offer information
 */
function updateBuyBox() {
    var useBuyBox = $('.miraklQuickViewBuyBox');

    if (useBuyBox.length !== 0) {
        var url = $('.miraklQuickViewBuyBox').data('url');
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                $('.miraklQuickViewBuyBox')
                    .empty()
                    .html(data);
                if (data.indexOf('offer-add-to-cart') !== -1) {
                    $('.product-option').addClass('d-none');
                }
                updateMiraklPrice();
                updateDuitesPrice();
                var offerState = $('#updated-offer-state');
                if(offerState.length > 0) {
                    var state = offerState[0].value;
                    updateMiraklOfferState(state);
                }
                $.spinner().stop();
            },
            error: function(err) {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Updates the buybox and load offers when a variant is changed
 * @param {Object} data - The response data from the ajax call(Product-Variant)
 */
function updateVariationBuyBox(data) {
    if (data && data.isMiraklEnabled) {
        $('.miraklQuickViewBuyBox')
            .empty()
            .html(data.quickViewBuyboxHtml);
        $.spinner().stop();
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement(isCartRec) {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    if ($('#consent-tracking').length !== 0) {
        $('#consent-tracking').remove();
    }

    var htmlString = '<!-- Modal -->' +
        '<div class="modal fade" id="quickViewModal" role="dialog" aria-modal="true">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog quick-view-dialog">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">';
    if (isCartRec) {
        htmlString = htmlString + '<div class="modal-header">' +
        '    <button type="button" class="close fa-pull-right product-rec-quickview-header" data-dismiss="modal">' +
        '        <span aria-hidden="true" class="cancel-icon svg-svg-22-cross svg-svg-22-cross-dims"></span>' +
        '    </button>' +
        '    <span aria-hidden="true" class="pdt-name-edit"></span>' +
        '    <span class="sr-only"> </span>' +
        '</div>';
    }
    htmlString = htmlString +
        '<div class="modal-body"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body')
        .append(htmlString);
}

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>')
        .append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer')
        .children();

    return {
        body: body,
        footer: footer
    };
}

/**
 * QV activate primary selected image zoom
 *
 * @param {this} $this - current element
 **/
function activateQVPrimaryImageZoom($this) {
    var zoomer = $('.enableQVZoomer');
    if (zoomer.length > 0) {
        var $element = $this.closest('.product-quickview');
        var serverUrl = $element.find('div.pdp-carousel')
            .data('scene7hosturl');
        var asset;
        var image;
        var params = {};
        image = $this.attr('src')
            .split('image/'); // eslint-disable-line
        asset = image[image.length - 1];
        params.asset = asset;
        params.serverurl = serverUrl;
        var containerId = $this.closest('.primary-image')
            .find('.s7-viewer-qv')
            .attr('id');
        var locale = zoomer[0].dataset.locale.split('_')[0];
        var zoomViewer = new s7viewers.FlyoutViewer({ // eslint-disable-line
            'containerId': containerId, // eslint-disable-line
            'params': params, // eslint-disable-line
            "localizedTexts":{
                "en":{
                    "FlyoutZoomView.TIP_BUBBLE_OVER": "Mouse over image for a closer look"
                },
                "fr":{
                    "FlyoutZoomView.TIP_BUBBLE_OVER": "Passez la souris sur l’image pour la voir de plus près"
                },
                defaultLocale: locale
            }
        }).init();
        $this.addClass('hide');
    }
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl, isCartRec) {
    $.spinner()
        .start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#quickViewModal .modal-body')
                .empty();
            $('#quickViewModal .modal-body')
                .html(parsedHtml.body);
            $('#quickViewModal .modal-footer')
                .html(parsedHtml.footer);
            $('#quickViewModal .size-chart')
                .attr('href', data.productUrl);
            if (data.fullPdpUrl) {
                $('#quickViewModal .view-full-product-info')
                    .attr('href', data.fullPdpUrl);
            } else {
                $('#quickViewModal .view-full-product-info')
                    .attr('href', data.productUrl);
            }
            $('#quickViewModal .enter-message')
                .text(data.enterDialogMessage);
            $('#quickViewModal')
                .css({
                    visibility: 'hidden'
                });
            $('#quickViewModal')
                .modal('show');

            if (isCartRec && data.product.brand.name) {
                parsedHtml.body.parents('.modal-content').find('.pdt-name-edit').text(data.product.brand.name);
            }

            isCartRec ? $('body').trigger('remoteTooltipInit') : $('body').trigger('adobeTagManager:showQuickView', data.product);

            $('body .quantity-val').on('input', function () {
                var element = $(this);
                var maxValue = parseInt($(this)
                    .attr('max'), 10);
                if (element.val() >= maxValue) {
                    $(this).val(maxValue);
                    $(this)
                        .closest('div')
                        .find('select[class*="quantity-select"]')
                        .val(maxValue)
                        .trigger('change');
                }
            });
            setTimeout(function () {
                base.activatePrimarySlick();
                base.activateVideoPlayer();
                $('body').trigger('remoteTooltipInit');
                formFields.updateSelect();
                formFields.quantityHoverBorder();
                updateBuyBox();
                updateSellerData(isCartRec);

                $('.quickview-close, .view-full-product-info')
                    .on('click', function (e) {
                        try {
                            persistentWishlist.makrSingleProductWishlisted($(this).closest('.product-quickview').data('pid'), $('#' + $(this).closest('.product-quickview').data('pid')));
                            // refresh the page on wishlist upon close of QV. This is to remove the tile on Wishlist landing page
                            if (e.currentTarget.className.indexOf('quickview-close') > -1 && $('.page').data('action') == 'Wishlist-Show') {
                                window.location.reload();
                            }
                        } catch (ex) { //eslint-disable-line
                            // do nothing
                        }
                        $('#quickViewModal')
                            .modal('hide');
                    });
                $('.product-quickview')
                    .on('click', '.primary-image img', function () {
                        if ($(this).parents('.product-rec-quickview').length) {
                            var pdpUrl = $(this).parents('.product-rec-quickview').find('.view-full-product-info').attr('href');
                            if (pdpUrl) {
                                window.location.href = pdpUrl;
                            }
                        } else {
                            activateQVPrimaryImageZoom($(this));
                        }
                    });
                $.spinner()
                    .stop();
                $('#quickViewModal')
                    .css({
                        visibility: 'visible'
                    });
                $('#quickViewModal .quickview_container').focus();
                try {
                    persistentWishlist.makrSingleProductWishlisted(data.product.id, $('.product-quickview'));
                    base.checkProductAvailability(data, $('.product-quickview'));
                } catch (e) { //eslint-disable-line
                    // do nothing
                }
            }, 300);
        },
        error: function () {
            $.spinner()
                .stop();
        }
    });
}

function showQuickview() {
    $('body')
        .on('click', '.quickview, .recommendation-products .product-is-rec .rec-quick-add', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this)
                .closest('a.quickview, .rec-quick-add')
                .data('quickviewUrl');
            $(e.target)
                .trigger('quickview:show');
            var isCartRec = $(this).hasClass('rec-quick-add');
            selectedValueUrl = `${selectedValueUrl}${selectedValueUrl.includes('?') ? `&recCheckout=${isCartRec}` : `?recCheckout=${isCartRec}`}`;
            getModalHtmlElement(isCartRec);
            fillModalElement(selectedValueUrl, isCartRec);
        });
};

function updateAttribute() {
    $('body')
        .on('product:afterAttributeSelect', function (e, response) {
            if(response && response.data && response.data.product && response.data.product.offerState) {
                var state = response.data.product.offerState;
                updateMiraklOfferState(state);
            }
            if ($('.modal.show .product-quickview>.bundle-items')
                .length) {
                $('.modal.show')
                    .find(response.container)
                    .data('pid', response.data.product.id);
                $('.modal.show')
                    .find(response.container)
                    .find('.product-id')
                    .text(response.data.product.id);
                $('.modal.show')
                    .find(response.container)
                    .find('.bf-product-id')
                    .text(response.data.product.id);
            } else if ($('.set-items')
                .length) {
                response.container.find('.product-id')
                    .text(response.data.product.id);
                response.container.find('.bf-product-id')
                    .text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview')
                    .data('pid', response.data.product.id);
                $('.modal.show .full-pdp-link')
                    .attr('href', response.data.product.selectedProductUrl);
                updateVariationBuyBox(response.data);
            }
        });
};
function updateAddToCart() {
    $('body')
        .on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart, button.offer-add-to-cart', response.$productContainer)
                .attr('disabled',
                    (!response.product.readyToOrder || !response.product.available));
            $('button.add-to-cart-global', response.$productContainer)
                    .attr('disabled', (!response.product.available));
            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');
            $('.add-to-cart-global, .offer-add-to-cart', dialog)
                .data('readytoorder', (response.product.readyToOrder && response.product.available));
            $('.add-to-cart-global, .offer-add-to-cart', dialog)
                .data('readytoordertext', (response.product.readyToOrderMsg));

            $('button.add-to-cart-global,button.offer-add-to-cart')
                .text(response.product.availability.buttonName);
        });
};
function updateAvailability() {
    $('body')
        .on('product:updateAvailability', function (e, response) {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);

            var btn = $('.quickview-productdetails button[class*="add-to-cart"]');
            if(btn.lenght > 0) {
                var available = response.product.readyToOrder && response.product.available;
                btn.toggleClass('disabled', !available);
                response.product.availability && !available ? btn.html(response.product.availability.soldout) : btn.html(response.product.availability.buttonName);
            }

            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog)
                .length) {
                // bundle all products
                var allAvailable = $('.product-availability', dialog)
                    .toArray()
                    .every(function (item) {
                        return $(item)
                            .data('available');
                    });

                var allReady = $('.product-availability', dialog)
                    .toArray()
                    .every(function (item) {
                        return $(item)
                            .data('ready-to-order');
                    });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog)
                    .empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
};

function updateMiraklPrice() {
    var prodPrice = $('.quickview-productdetails .prod-price');
    var newPrice = $('.mirakl-updated-price');
    if (prodPrice.length > 0 && newPrice.length > 0) {
        newPrice.detach();
        newPrice.removeClass('d-none');
        prodPrice.replaceWith(newPrice.html());
    }
}

function updateDuitesPrice() {
    var dutiesPrice = document.getElementsByClassName('qv_duties')[0].innerHTML;
    var offerPrice = document.getElementsByClassName('qv_formatted_sale_price')[0].innerHTML;
    var offerPriceSpan = $('.price-value-section .formatted_sale_price');
    var dutiesDiv = $('.product-edit-price .duties');
    if (dutiesDiv.length > 0) {
        if (dutiesPrice) {
            var offerPriceSpan = $('.price-value-section .formatted_sale_price');
            if (offerPriceSpan.length > 0 && offerPrice && dutiesPrice) {
                dutiesDiv.empty().html(dutiesPrice);
                offerPriceSpan.empty().html(offerPrice);
            }
        } else {
            dutiesDiv.empty();
        }
    }
}

function updateMiraklOfferState(state) {
    if(state) {
        var stateDiv = $('.product-state');
        var newState = $('#offer-state-new');
        var newStateFr = $('#offer-state-new-fr');
        if (stateDiv.length === 0) return;
        if (newState.length === 0 || state === newState[0].value) {
            stateDiv[0].innerText = '';
        } else if (newStateFr.length === 0 || state === newStateFr[0].value) {
            stateDiv[0].innerText = '';
        } else {
            stateDiv[0].innerText = state;
        }
    }
}

module.exports = {
    showQuickview: showQuickview,
    updateAttribute: updateAttribute,
    updateAddToCart: updateAddToCart,
    updateAvailability: updateAvailability,
    miraklQuantityForm: miraklQuantityForm,
    addOffer: addOffer
};
