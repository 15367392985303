'use strict';

/**
 * Dynamically replace the returned WAInclude element by the isslot from Salesforce
 * to a direct request to their internal system call of Slot-Request followed by
 * a direct call to CQRecomm-Start to populate the recommendations
 * @param {Object} addToCartConfirmationModal popup after product added to cart
 */
function addDynamicAddToCartRecommendations(addToCartConfirmationModal) {
    const appendSlotHTMLElements = function (el) {
        if (el.nodeName === 'SCRIPT') {
            var script = document.createElement('script');
            script.innerHTML = el.innerHTML;
            document.querySelector('#productAddedToCartModal .modal-body .items-like').appendChild(script);
        } else {
            document.querySelector('#productAddedToCartModal .modal-body .items-like').append(el);
        }
    };

    const $modalHtml = $(addToCartConfirmationModal);
    const $recsDynamicInclude = $modalHtml.find('.add-to-bag-confirm-recs');
    if ($recsDynamicInclude.length && $recsDynamicInclude.data('einstein-request')) {
        fetch($recsDynamicInclude.data('einstein-request'))
            .then((response) => response.text())
            .then((htmlEl) => {
                $(htmlEl).get().forEach((el) => appendSlotHTMLElements(el));
                window._hbcSlider.einsteinRecommendationListener();
            });
    }
}

/**
 * @param {Object} addToCartConfirmationModal popup after product added to cart
 */
function showAddToCartConfirmation(addToCartConfirmationModal) {
    var htmlString = '<!-- Modal -->' +
        '<div class="modal modal-flyout fade gwp right product-added-to-cart-modal" id="productAddedToCartModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog gift-offers quick-view-dialog ">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <button type="button" class="close fa-pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true" class="cancel-icon svg-svg-22-cross svg-svg-22-cross-dims font-icon-added"></span>' +
        '    </button>' +
        '    <span aria-hidden="true" class="choice-bonus-header"></span>' +
        '        <span>' + addedToBagLabel + '</span>' + // eslint-disable-line no-undef
        '    <span class="sr-only"> </span>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body')
        .append(htmlString);

    $('#productAddedToCartModal').modal({ focus: false });
    $('.quick-view-dialog .modal-content')
        .spinner()
        .start();

    $('#productAddedToCartModal .modal-body').empty();
    $('#productAddedToCartModal .modal-body').html(addToCartConfirmationModal);
    addDynamicAddToCartRecommendations(addToCartConfirmationModal);
}
    /**
     * PDP Add to cart model actions.
     *
    **/
    $('body').on('shown.bs.modal', '#productAddedToCartModal', function () {
        if($('.confirm-container').length){
            $('#productAddedToCartModal').modal({ focus: true });
            $('#productAddedToCartModal .quick-view-dialog .close').focus();
        }
    });

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    if (response) {
        $('.minicart')
            .trigger('count:update', response);
        if (!response.error && response.addToCartConfirmationModal) {
            if (($('.pg-name').length > 0 && $('.pg-name').val() === 'wishlist')) {
                $('body').trigger('adobeTagManager:moveFavToBag', response);
            } else {
                $('body').trigger('adobeTagManager:addToBag', response);
            }

            showAddToCartConfirmation(response.addToCartConfirmationModal);
        }

        // don't show the error message in PLP (Product array)-SFDEV-7748
        if (response.sourcepage && response.sourcepage === 'pdp') {
            if ($('.add-to-cart-messages').length === 0) {
                $('.shipping-section-PDP').append(
                    '<div class="add-to-cart-messages pdp"></div>'
                );
            }
            if (response.message !== '') {
                $('.add-to-cart-messages')
                    .html(
                        // eslint-disable-next-line no-undef
                        '<div class="error" role="alert">' +
                        response.message +
                        '</div>'
                    );
            }

            setTimeout(function () {
                $('.add-to-cart-messages .error')
                    .remove();
            }, 5000);
        }
    }
}

/**
 * Returns price value
 * @param {models.price.default.DefaultPrice} priceModel - The price model
 * @returns {number} The price value
 */
function getPrice(priceModel) {
    return !priceModel.sales ? priceModel.list.value : priceModel.sales.value;
}

/**
 * Returns list price value
 * @param {models.price.default.DefaultPrice} priceModel - The price model
 * @returns {number} The list price value
 */
function getListPrice(priceModel) {
    return priceModel.list ? priceModel.list.value : '';
}

/**
 * Add an offer to the cart
 */
function addOfferToCart() {
    $(document).on('click', 'button.offer-add-to-cart:not(.prdt_cartwl_btn)', function () {
        var addToCartObject = $(this).data('mirakl')
            ? $(this).data('mirakl')
            : null;
        if (addToCartObject) {
            $('.quick-view-dialog .modal-content').spinner().start() || $.spinner().start();
            var addToCartUrl = addToCartObject.url;
            var pid = addToCartObject.pid;
            var priceInfo = addToCartObject.priceInfo;
            var productName = addToCartObject.productName;
            var channels = addToCartObject.channels;
            var price = getPrice(addToCartObject.price);
            var listPrice = getListPrice(addToCartObject.price);
            var operatorProductId = $(this).data('pid');
            var shopName = addToCartObject.shopName;
            var offerState = addToCartObject.offerState;
            var shopId = addToCartObject.shopId;
            var quantity = $('.quantity')
                .find('.quantity-select')
                .val();
            var form = {
                pid: pid,
                price: price,
                channels: channels,
                listPrice: listPrice,
                productName: productName,
                quantity: quantity,
                operatorProductId: operatorProductId,
                shopName: shopName,
                offerState: offerState,
                shopId: shopId,
                priceInfo: priceInfo,
                addToCartInfo: JSON.stringify(addToCartObject)
            };

            $(this).trigger('updateAddToCartFormData', form);
            var $productContainer = $(this).closest('.product-detail');

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        if ($productContainer.length && $productContainer.parents('.cart-page').length && $productContainer.hasClass('product-rec-quickview')) {
                            window.location.reload();
                        }
                        else {
                            $('body').trigger('triggerHandlePostCartAdd', data);
                            $('body').trigger('product:afterAddToCart', data);
                            $.spinner().stop();
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        }
        $('#allOffersModal').modal('hide');
    });
}

$('body').on('triggerHandlePostCartAdd', function (e, data) {
    handlePostCartAdd(data);
});

module.exports = {
    addOfferToCart: addOfferToCart
};
